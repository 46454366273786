import React from "react"
import kebabCase from "lodash/kebabCase"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import { stripProtocol } from "../util/helpers"
import { projectCategoryVariant } from "../util/styles"
import { PillLink } from "../components/ui/pill"
import { PageHeader } from "../components/header"
import TagsList from "../components/tags"

export default ({ data }) => {
  const { html, excerpt, frontmatter } = data.markdownRemark

  const {
    title,
    description,
    path,
    date,
    category,
    tags,
    project,
    image,
  } = frontmatter

  const { technologies, repository, website } = project
  return (
    <Layout>
      <SEO
        title={title}
        description={description || excerpt}
        path={path}
        datePublished={date}
        image={image}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <PageHeader>{title}</PageHeader>
        <div className="mb-10">
          <ul className="text-gray-600">
            <li className="mb-2 flex items-center">
              <span className="mr-2">Type:</span>
              <PillLink
                to={`/projects/categories/${category}`}
                variant={projectCategoryVariant(category)}
              >
                {category}
              </PillLink>
            </li>
            <li className="mb-2 flex items-center">
              <span className="mr-2">Technologies:</span>
              <ul className="flex space-x-2">
                {technologies.map(({ name, id }) => (
                  <li key={name}>
                    <Link to={`/projects/technologies/${kebabCase(id)}/`}>
                      <span className="text-secondary-400 hover:text-secondary-600 capitalize">
                        {name}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            {repository && (
              <li className="mb-2 flex items-center">
                <span className="mr-2">Repository:</span>
                <a
                  className="text-primary-500 hover:text-primary-700 transition ease-in duration-100 flex items-center"
                  href={repository}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {stripProtocol(repository)}
                </a>
              </li>
            )}
            {website && (
              <li className="mb-2 flex items-center">
                <span className="mr-2">Website:</span>
                <a
                  className="text-primary-500 hover:text-primary-700 transition ease-in duration-100 flex items-center"
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {stripProtocol(website)}
                </a>
              </li>
            )}
          </ul>
        </div>
        <Content>{html}</Content>
        <div className="text-xs flex items-center py-6">
          <span className="text-gray-500 mr-3">Tags:</span>
          <TagsList tags={tags} />
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
        category
        image {
          publicURL
        }
        tags {
          tagId
          tag
        }
        project {
          repository
          website
          technologies {
            id
            name
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
