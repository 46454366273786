export const pillStyling = variant => {
  switch (variant) {
    case "blue":
      return "bg-blue-200 hover:bg-blue-300 text-blue-400 hover:text-blue-600"
    case "red":
      return "bg-red-200 hover:bg-red-300 text-red-400 hover:text-red-600"
    case "green":
      return "bg-green-200 hover:bg-green-300 text-green-400 hover:text-green-600"
    case "yellow":
      return "bg-yellow-200 hover:bg-yellow-300 text-yellow-400 hover:text-yellow-600"
    case "orange":
      return "bg-orange-200 hover:bg-orange-300 text-orange-400 hover:text-orange-600"
    case "primary":
      return "bg-primary-200 hover:bg-primary-300 text-primary-400 hover:text-primary-600"
    case "indigo":
      return "bg-indigo-200 hover:bg-indigo-300 text-indigo-400 hover:text-indigo-600"
    case "secondary":
      return "bg-gray-200 hover:bg-gray-300 text-secondary-400 hover:text-secondary-600"
    case "gray":
    default:
      return "bg-gray-200 hover:bg-gray-300 text-gray-400 hover:text-gray-500"
  }
}

export const projectCategoryVariant = category => {
  let variant = "gray"
  switch (category) {
    case "application":
      variant = "blue"
      break
    case "library":
      variant = "green"
      break
  }
  return variant
}
export const blogCategoryVariant = category => {
  let variant = "gray"
  switch (category) {
    case "frontend":
      variant = "red"
      break
    case "backend":
      variant = "orange"
      break
  }
  return variant
}
