import React from "react"
import kebabCase from "lodash/kebabCase"
import { Link } from "gatsby"
import { pillStyling } from "../../util/styles"
export const Pill = props => {
  const { variant, children, ...rest } = props
  const base = `text-xs bg-opacity-75 px-3 py-1 rounded-full capitalize ${pillStyling(
    variant
  )}`
  return (
    <span className={base} {...rest}>
      {children}
    </span>
  )
}
export const PillLink = props => {
  const { to, variant, children } = props
  let parts = to.split("/")
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i]
    if (part === "/") continue
    parts[i] = kebabCase(part)
  }
  return (
    <Link to={parts.join("/")}>
      <Pill variant={variant}>{children}</Pill>
    </Link>
  )
}
